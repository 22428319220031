import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  chats: [],
  contacts: [],
  selectedContact: {},
  isLoading: false,
  error: null,
};

export const ChatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    fetchChats: (state) => {
      state.isLoading = true;
    },
    fetchContacts: (state) => {
      state.isLoading = true;
    },
    fetchContactsSuccess: (state, action) => {
      state.contacts = action.payload;
    },

    selectContact: (state, action) => {
      state.selectedContact = action.payload;
    },

    getChats: (state, action) => {
      state.chats = action.payload;
      state.isLoading = false;
    },

    sendMsg: (state) => {
      state.isLoading = true;
      state.error = null;
    },

    sendMsgFailure: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    resetChats: (state) => {
      state.selectedContact = {};
      state.chats = [];
      state.isLoading = false;
    },
  },
});

export const {
  getChats,
  sendMsg,
  sendMsgFailure,
  fetchChats,
  fetchContacts,
  fetchContactsSuccess,
  selectContact,
  resetChats,
} = ChatSlice.actions;

export default ChatSlice.reducer;
