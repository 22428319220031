import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import Loadable from '../layouts/loader/Loadable';
/****Layouts*****/

const FullLayout = Loadable(lazy(() => import('../layouts/FullLayout')));
const BlankLayout = Loadable(lazy(() => import('../layouts/BlankLayout')));
const MidLayout = Loadable(lazy(() => import('../layouts/MidLayout')));

/***** Pages ****/
const BusinessBookingsList = Loadable(
  lazy(() => import('../views/businessBookingsList/businessBookingsList')),
);
const BookingsDetails = Loadable(lazy(() => import('../views/bookingDetails/bookingsDetails')));
const Reschedule = Loadable(lazy(() => import('../views/reschedule/reschedule')));
const Services = Loadable(lazy(() => import('../views/services/services')));
const NewService = Loadable(lazy(() => import('../views/newService/newService')));
const Specialists = Loadable(lazy(() => import('../views/specialists/specialists')));
const SpecialistsDetail = Loadable(lazy(() => import('../views/specialists/specialistDetail')));
const AddSpecialists = Loadable(lazy(() => import('../views/addSpecialists/addSpecialists')));
const ServiceDetail = Loadable(lazy(() => import('../views/serviceDetail/serviceDetail')));
const StaffCalender = Loadable(lazy(() => import('../views/staffCalender/staffCalender')));
const Profile = Loadable(lazy(() => import('../views/profile/index')));
const CardsDetails = Loadable(lazy(() => import('../views/profile/cardsDetails')));
const Settings = Loadable(lazy(() => import('../views/settings/settings')));
const BusinessSchedule = Loadable(lazy(() => import('../views/businessSchedule/businessSchedule')));
const Notifications = Loadable(lazy(() => import('../views/notifications/notifications')));
const About = Loadable(lazy(() => import('../views/aboutUs/aboutUs')));
const Privacy = Loadable(lazy(() => import('../views/privacyPolicies/privacyPolicies')));
const TermsCondition = Loadable(lazy(() => import('../views/terms&conditions/term&conditions')));
const Chat = Loadable(lazy(() => import('../views/apps/chat/Chat')));
/***** Auth Pages ****/
const Error = Loadable(lazy(() => import('../views/auth/Error')));
const RegisterFormik = Loadable(lazy(() => import('../views/auth/RegisterFormik')));
const CompanyPersons = Loadable(lazy(() => import('../views/auth/companyPersons')));
const LoginFormik = Loadable(lazy(() => import('../views/auth/LoginFormik')));
const Maintanance = Loadable(lazy(() => import('../views/auth/Maintanance')));
const LockScreen = Loadable(lazy(() => import('../views/auth/LockScreen')));
const RecoverPassword = Loadable(lazy(() => import('../views/auth/RecoverPassword')));
const VerifyOTP = Loadable(lazy(() => import('../views/auth/verifyOtp')));
const NewPassword = Loadable(lazy(() => import('../views/auth/newpassword')));
const BusinessServiceType = Loadable(
  lazy(() => import('../views/businessServiceTypes/businessServiceTypes')),
);

const BusinessServiceTypeList = Loadable(
  lazy(() => import('../views/BusinessServiceTypeList/BusinessServiceTypeList')),
);

/*****Routes******/
const commonComponents = {
  element: <AddSpecialists />,
};
const ThemeRoutes = (user, userEmailForForgotPwd, isPinOTPMatched) => [
  {
    path: '/',
    element: user ? <FullLayout /> : <Navigate to="/login" />,
    // element: <FullLayout />,
    private: true,
    children: [
      { path: '/dashboard', name: 'Dashboard', exact: true, element: <BusinessBookingsList /> },
      {
        path: '/booking-details',
        name: 'bookingsDetails',
        exact: true,
        element: <BookingsDetails />,
      },
      {
        path: '/services',
        name: 'Services',
        exact: true,
        element: <Services />,
      },
      {
        path: '/new-service',
        name: 'Newservice',
        exact: true,
        element: <NewService />,
      },
      {
        path: '/service-types',
        name: 'serviceTypes',
        exact: true,
        element: <BusinessServiceType />,
      },
      {
        path: '/service-types-list',
        name: 'serviceTypesList',
        exact: true,
        element: <BusinessServiceTypeList />,
      },
      {
        path: '/service-details',
        name: 'Servicedetails',
        exact: true,
        element: <ServiceDetail />,
      },
      {
        path: '/business-schedule',
        name: 'BusinessSchedule',
        exact: true,
        element: <BusinessSchedule />,
      },
      { path: '/messages', name: 'Chat', exact: true, element: <Chat /> },
      {
        path: '/staff-calender',
        name: 'staffcalender',
        exact: true,
        element: <StaffCalender />,
      },
      { path: '/reschedule', name: 'reschedule', exact: true, element: <Reschedule /> },
      { path: '/specialist', name: 'specialist', exact: true, element: <Specialists /> },
      {
        path: '/specialist-Details',
        name: 'specialist',
        exact: true,
        element: <SpecialistsDetail />,
      },
      { path: '/add-specialist', name: 'addspecialist', exact: true, ...commonComponents },
      { path: '/update-specialist', name: 'updatespecialist', exact: true, ...commonComponents },

      { path: '*', element: <Navigate to="/404" /> },
    ],
  },
  {
    path: '/',
    element: user ? <MidLayout /> : <Navigate to="/login" />,
    // element: <MidLayout />,
    private: true,
    children: [
      {
        path: '/profile',
        name: 'Profile',
        exact: true,
        element: <Profile />,
      },
      // {
      //   path: '/company-persons',
      //   name: 'companyPersons',
      //   exact: true,
      //   element: <CompanyPersons />,
      // },
      {
        path: '/cards-details',
        name: 'CardsDetails',
        exact: true,
        element: <CardsDetails />,
      },
      { path: '/settings', name: 'Settings', exact: true, element: <Settings /> },
      { path: '/notifications', name: 'Notifications', exact: true, element: <Notifications /> },
      { path: '/about', name: 'About', exact: true, element: <About /> },
      { path: '/privacy', name: 'Privacy', exact: true, element: <Privacy /> },
      { path: '/terms', name: 'Terms', exact: true, element: <TermsCondition /> },
      { path: '*', element: <Navigate to="/404" /> },
    ],
  },
  {
    path: '/',
    element: <BlankLayout />,
    children: [
      { path: '404', element: <Error /> },
      { path: '*', element: <Navigate to="/404" /> },
      { path: 'register', element: <RegisterFormik /> },
      { path: 'login', element: <LoginFormik /> },
      { path: 'maintanance', element: <Maintanance /> },
      { path: 'lockscreen', element: <LockScreen /> },
      {
        path: 'recoverpwd',
        element: userEmailForForgotPwd === '' ? <RecoverPassword /> : <Navigate to="/verify-otp" />,
      },
      {
        path: 'verify-otp',
        element: userEmailForForgotPwd !== '' ? <VerifyOTP /> : <Navigate to="/login" />,
      },
      {
        path: 'new-password',
        element:
          userEmailForForgotPwd !== '' && isPinOTPMatched === 'true' ? (
            <NewPassword />
          ) : (
            <Navigate to="/verify-otp" />
          ),
      },
    ],
  },
];

export default ThemeRoutes;
