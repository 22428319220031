import { takeLatest, put } from 'redux-saga/effects';
import { $authHost } from '../axios';
import {
  updateBookingSchedule,
  updateBookingScheduleSuccess,
  updateBookingScheduleError,
  getAllBookingSchedules,
  getAllBookingSchedulesSuccess,
  getAllBookingSchedulesError,
} from './bookingRescheduleSlice';

function* updateBookingSchedules(action) {
  const { payload } = action;

  try {
    const response = yield $authHost.post(`/service-booking-schedule/reschedule-booking`, payload);
    yield put(updateBookingScheduleSuccess(response?.data));
  } catch (error) {
    if (error.response?.status === 500) {
      const errorPreview = {
        code: 500,
        message: 'Internal Server Error',
        description: 'Internal Server Error',
      };
      yield put(updateBookingScheduleError(errorPreview));
    } else if (error.response?.data) {
      const { code, message, description } = error.response.data;
      const errorPreview = { code, message, description };
      yield put(updateBookingScheduleError(errorPreview));
    }
  }
}

function* fetchAllBookingSchedules() {
  try {
    const response = yield $authHost.get(`/service-booking-schedule/get-all`);

    if(response.status ===200){
      yield put(getAllBookingSchedulesSuccess(response.data.data.bookingSchedules))
    }

  } catch (error) {
    if (error.response?.status === 500) {
      const errorPreview = {
        code: 500,
        message: 'Internal Server Error',
        description: 'Internal Server Error',
      };
      yield put(getAllBookingSchedulesError(errorPreview));
    } else if (error.response?.data) {
      const { code, message, description } = error.response.data;
      const errorPreview = { code, message, description };
      yield put(getAllBookingSchedulesError(errorPreview));
    }
  }
}

export default function* bookingScheduleSaga() {
  yield takeLatest(updateBookingSchedule.type, updateBookingSchedules);
  yield takeLatest(getAllBookingSchedules.type, fetchAllBookingSchedules);
}
