import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  bookingsList: [],
  bookingDetails: [],
  isLoading: false,
  success: null,
  error: null,
};

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    getBookingsList: (state, action) => {
      state.isLoading = true;
    },
    getBookingsListSuccess: (state, action) => {
      state.isLoading = false;
      state.bookingsList = action.payload;
    },
    getBookingsListFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },

    // booking details
    getBookingsDetail: (state, action) => {
      state.isLoading = true;
    },
    getBookingsDetailSuccess: (state, action) => {
      state.isLoading = false;
      state.bookingDetails = action.payload;
    },
    // Accept a service by business
    acceptService: (state, action) => {
      state.isLoading = true;
    },
    acceptServiceSuccess: (state, action) => {
      state.isLoading = false;
      state.success = action.payload;
    },
    acceptServiceFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    // Accept a booking by business
    acceptBooking: (state, action) => {
      state.isLoading = true;
    },
    acceptBookingSuccess: (state, action) => {
      state.isLoading = false;
      state.success = action.payload;
    },
    acceptBookingFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    // Cancel a service by business
    cancelService: (state, action) => {
      state.isLoading = true;
    },
    cancelServiceSuccess: (state, action) => {
      state.isLoading = false;
      state.success = action.payload;
    },
    cancelServiceFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    // Cancel a booking by business
    cancelBooking: (state, action) => {
      state.isLoading = true;
    },
    cancelBookingSuccess: (state, action) => {
      state.isLoading = false;
      state.success = action.payload;
    },
    cancelBookingFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    // Mark booking as No Show
    markNoShow: (state, action) => {
      state.isLoading = true;
    },
    markNoShowSuccess: (state, action) => {
      state.isLoading = false;
      state.success = action.payload;
    },
    markNoShowFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    // complete booking
    completeBooking: (state, action) => {
      state.isLoading = true;
    },
    completeBookingSuccess: (state, action) => {
      state.isLoading = false;
      state.success = action.payload;
    },
    // assign specialist to service
    assignSpecialist: (state, action) => {
      state.isLoading = true;
    },
    assignSpecialistSuccess: (state, action) => {
      state.isLoading = false;
      state.success = action.payload;
    },
    // reset states
    resetDashboardStates: (state) => {
      state.isLoading = false;
      state.success = null;
      state.error = null;
    },
  },
});

export const {
  getBookingsList,
  getBookingsListSuccess,
  getBookingsListFailure,
  getBookingsDetail,
  getBookingsDetailSuccess,
  acceptService,
  acceptServiceSuccess,
  acceptServiceFailure,
  acceptBooking,
  acceptBookingSuccess,
  acceptBookingFailure,
  cancelService,
  cancelServiceSuccess,
  cancelServiceFailure,
  cancelBooking,
  cancelBookingSuccess,
  cancelBookingFailure,
  markNoShow,
  markNoShowSuccess,
  completeBooking,
  completeBookingSuccess,
  markNoShowFailure,
  assignSpecialist,
  assignSpecialistSuccess,
  resetDashboardStates,
} = dashboardSlice.actions;

export default dashboardSlice.reducer;
