import { takeLatest, put } from 'redux-saga/effects';
import { $host, $authHost } from 'store/axios';
import {
  getBusinessServices,
  getBusinessServicesSuccess,
  getBusinessServicesFailure,
  getBusinessServiceDetails,
  getBusinessServiceDetailsSuccess,
  getBusinessServiceDetailsFailure,
  addBusinessService,
  addBusinessServiceSuccess,
  addBusinessServiceFailure,
  updateBusinessService,
  updateBusinessServiceSuccess,
  updateBusinessServiceFailure,
  deleteAService,
  deleteAServiceSuccess,
  deleteAServiceFailure,
  resetBusinessServicesState,
} from './servicesSlice';

// get service list
function* getServicesList(action) {
  const { payload } = action;
  try {
    const response = yield $authHost.get(`/business-services/business-all-services/${payload}`);
    if (response.data.code === 200) {
      yield put(getBusinessServicesSuccess(response.data.data.business_services));
    }
  } catch (error) {
    if (error.response.status === 500) {
      const errorPreview = {
        code: 500,
        message: 'Internal Server Error',
        description: 'Internal Server Error',
      };
      yield put(getBusinessServicesFailure(errorPreview));
    }
    if (error.response && error.response.data) {
      const { code, message, description } = error.response.data;
      const errorPreview = { code, message, description };
      yield put(getBusinessServicesFailure(errorPreview));
    }
  }
}

// get business service details

function* getServiceDetail(action) {
  const { businessId, serviceId } = action.payload;
  try {
    const response = yield $authHost.get(`/business-services/business-service/${businessId}/${serviceId}`);
    if (response.data.code === 200) {
      yield put(getBusinessServiceDetailsSuccess(response.data.data.business_service));
    }
  } catch (error) {
    if (error.response.status === 500) {
      const errorPreview = {
        code: 500,
        message: 'Internal Server Error',
        description: 'Internal Server Error',
      };
      yield put(getBusinessServiceDetailsFailure(errorPreview));
    }
    if (error.response && error.response.data) {
      const { code, message, description } = error.response.data;
      const errorPreview = { code, message, description };
      yield put(getBusinessServiceDetailsFailure(errorPreview));
    }
  }
}

// create service
function* addServiceBusiness(action) {
  const { payload } = action;
  console.log('payloadpayload', payload);
  const formData = new FormData();
  formData.append('title', payload.title);
  formData.append('is_fix_price', payload.is_fix_price ? 1 : 0);
  formData.append('price', +payload.price);
  formData.append('min_price', payload.min_price === '' ? 0 : +payload.min_price);
  formData.append('discount', +payload.discount);
  formData.append('service_time', payload.service_time);
  formData.append('description', payload.description);
  formData.append('business_id', payload.business_id);
  formData.append('deposit_fee', payload.deposit_fee);
  formData.append('cancellation_hours', payload.cancellation_hours);
  formData.append('specialists', payload.specialists);
  formData.append('image', payload.image);
  formData.append('service_type_id', payload.serviceType);
  payload.service_gallery.forEach((file) => {
    formData.append('service_gallery', file);
  });

  try {
    const response = yield $authHost.post('/business-services/create-business-services', formData);
    if (response.data.code === 200) {
      const success = { message: response.data.message, description: response.data.description };
      yield put(addBusinessServiceSuccess(success));
    } else {
      const { code, message, description } = response.data;
      const errorPreview = { code, message, description };
      yield put(addBusinessServiceFailure(errorPreview));
    }
  } catch (error) {
    if (error.response?.status === 500) {
      const errorPreview = {
        code: 500,
        message: error.message,
        description: error.description,
      };
      yield put(addBusinessServiceFailure(errorPreview));
    }
    if (error.response && error.response.data) {
      const { code, message, description } = error.response.data;
      const errorPreview = { code, message, description };
      yield put(addBusinessServiceFailure(errorPreview));
    }
  }
}

// update services
function* updateServiceBusiness(action) {
  const { payload } = action;
  const formData = new FormData();
  formData.append('title', payload.title);
  formData.append('is_fix_price', payload.is_fix_price ? 1 : 0);
  formData.append('price', payload.price === '' ? 0 : +payload.price);
  formData.append('min_price', payload.min_price === '' ? 0 : +payload.min_price);
  formData.append('discount', +payload.discount);
  formData.append('service_time', payload.service_time);
  formData.append('description', payload.description);
  formData.append('deposit_fee', payload.deposit_fee);
  formData.append('cancellation_date', payload.cancellation_date);
  formData.append('specialists', payload.specialists);
  formData.append('service_type_id', payload.serviceType);
  // formData.append('image', payload.image);
  // formData.append('service_gallery', payload.service_gallery);

  try {
    const response = yield $authHost.post(
      `/business-services/update-business-service/${payload.businessServiceId}`,
      formData,
    );
    if (response.data.code === 200) {
      const success = { message: response.data.message, description: response.data.description };
      yield put(updateBusinessServiceSuccess(success));
    } else {
      const { code, message, description } = response.data;
      const errorPreview = { code, message, description };
      yield put(updateBusinessServiceFailure(errorPreview));
    }
  } catch (error) {
    if (error.response?.status === 500) {
      const errorPreview = {
        code: 500,
        message: error.message,
        description: error.description,
      };
      yield put(updateBusinessServiceFailure(errorPreview));
    }
    if (error.response && error.response.data) {
      const { code, message, description } = error.response.data;
      const errorPreview = { code, message, description };
      yield put(updateBusinessServiceFailure(errorPreview));
    }
  }
}

// delete services
function* deleteService(action) {
  const { payload } = action;
  try {
    const response = yield $authHost.delete(
      `/business-services/delete-business-service/${payload}`,
    );
    if (response.data.code === 200) {
      const success = { message: response.data.message, description: response.data.description };
      yield put(deleteAServiceSuccess(success));
    }
  } catch (error) {
    if (error.response?.status === 500) {
      const errorPreview = {
        code: 500,
        message: error.message,
        description: error.description,
      };
      yield put(deleteAServiceFailure(errorPreview));
    }
    if (error.response && error.response.data) {
      const { code, message, description } = error.response.data;
      const errorPreview = { code, message, description };
      yield put(deleteAServiceFailure(errorPreview));
    }
  }
}

export default function* servicesSaga() {
  yield takeLatest(getBusinessServices.type, getServicesList);
  yield takeLatest(getBusinessServiceDetails.type, getServiceDetail);
  yield takeLatest(addBusinessService.type, addServiceBusiness);
  yield takeLatest(updateBusinessService.type, updateServiceBusiness);
  yield takeLatest(deleteAService.type, deleteService);
}
