import { takeLatest, put } from 'redux-saga/effects';
import { $host, $authHost } from '../axios';
import {
  // serviceCustomer
  getUserProfile,
  getUserProfileSuccess,
  getUserProfileFailure,
  // business profile update
  updateProfile,
  updateProfileFailure,
  updateProfileSuccess,
  updateBusinessProofOfAddress,
  updateBusinessProofOfAddressSuccess,
  updateBusinessProofOfAddressFailure,
  updateProofOfCertification,
  updateProofOfCertificationSuccess,
  updateProofOfCertificationFailure,
  updateProofOfLicense,
  updateProofOfLicenseSuccess,
  updateProofOfLicenseFailure,
  getBankDetails,
  getBankDetailsSuccess,
  getBankDetailsFailure,
} from './profileSlice';
import { authMeStart } from 'store/auth/authSlice';
// updateProfile
function* profileUpdate(action) {
  const { payload } = action;
  try {
    const formdata = new FormData();
    const payloadObj = payload.fields;
    formdata.append('business_name', payloadObj.business_name);
    // formdata.append('first_name', payloadObj.firstname);
    // formdata.append('last_name', payloadObj.lastname);
    formdata.append('address_line1', payloadObj.address_line1);
    formdata.append('city', payloadObj.city);
    formdata.append('phone', payloadObj.phone);
    formdata.append('company_post_code', payloadObj.post_code);
    formdata.append('company_house_number', payloadObj.company_house_number);
    if (payloadObj.image instanceof File) {
      formdata.append('image', payloadObj.image);
    }
    const response = yield $authHost.patch(`/businesses/${payload.userId}`, formdata);
    if (response.status === 200) {
      const success = { message: response.data.message, description: response.data.description };
      yield put(authMeStart());
      yield put(updateProfileSuccess(success));
    }
  } catch (error) {
    if (error.response && error.response.data) {
      const { code, message, description } = error.response.data;
      const errorPreview = { code, message, description };
      yield put(updateProfileFailure(errorPreview));
    }
  }
}
// Proof Of Address
function* businessProofOfAddress(action) {
  const { payload } = action;
  const formData = new FormData();
  formData.append('address_proof', payload.file);
  try {
    const response = yield $authHost.post(
      `/businesses/update-address-proof/${payload.businessId}`,
      formData,
    );
    if (response.status === 200) {
      const success = { message: response.data.message, description: response.data.description };
      yield put(authMeStart());
      yield put(updateBusinessProofOfAddressSuccess(success));
    }
  } catch (error) {
    if (error.response.status === 500) {
      const errorPreview = {
        code: 500,
        message: 'Internal Server Error',
        description: 'Internal Server Error',
      };
      yield put(updateBusinessProofOfAddressFailure(errorPreview));
    }
    if (error.response && error.response.data) {
      const { code, message, description } = error.response.data;
      const errorPreview = { code, message, description };
      yield put(updateBusinessProofOfAddressFailure(errorPreview));
    }
  }
}
// Proof Of Certification Incorporation
function* businessProofOfCertification(action) {
  const { payload } = action;
  const formData = new FormData();
  formData.append('certificate_proof', payload.file);
  try {
    const response = yield $authHost.post(
      `/businesses/update-certificate-proof/${payload.businessId}`,
      formData,
    );
    if (response.status === 200) {
      const success = { message: response.data.message, description: response.data.description };
      yield put(authMeStart());
      yield put(updateProofOfCertificationSuccess(success));
    }
  } catch (error) {
    if (error.response.status === 500) {
      const errorPreview = {
        code: 500,
        message: 'Internal Server Error',
        description: 'Internal Server Error',
      };
      yield put(updateProofOfCertificationFailure(errorPreview));
    }
    if (error.response && error.response.data) {
      const { code, message, description } = error.response.data;
      const errorPreview = { code, message, description };
      yield put(updateProofOfCertificationFailure(errorPreview));
    }
  }
}
// Proof Of Trading License
function* businessProofOfLicense(action) {
  const { payload } = action;
  const formData = new FormData();
  formData.append('license_proof', payload.file);
  try {
    const response = yield $authHost.post(
      `/businesses/update-license-proof/${payload.businessId}`,
      formData,
    );
    if (response.status === 200) {
      const success = { message: response.data.message, description: response.data.description };
      yield put(authMeStart());
      yield put(updateProofOfLicenseSuccess(success));
    }
  } catch (error) {
    if (error.response.status === 500) {
      const errorPreview = {
        code: 500,
        message: 'Internal Server Error',
        description: 'Internal Server Error',
      };
      yield put(updateProofOfLicenseFailure(errorPreview));
    }
    if (error.response && error.response.data) {
      const { code, message, description } = error.response.data;
      const errorPreview = { code, message, description };
      yield put(updateProofOfLicenseFailure(errorPreview));
    }
  }
}

// get bank details

function* businessBankDetails(action) {
  const { payload } = action;
  try {
    const response = yield $authHost.get(`/beneficiaries/${payload}`);
    if (response.status === 200) {
      console.log(response);
      yield put(getBankDetailsSuccess(response?.data?.data?.business_beneficiaries));
    }
  } catch (error) {
    if (error.response.status === 500) {
      const errorPreview = {
        code: 500,
        message: 'Internal Server Error',
        description: 'Internal Server Error',
      };
      yield put(getBankDetailsFailure(errorPreview));
    }
    if (error.response && error.response.data) {
      const { code, message, description } = error.response.data;
      const errorPreview = { code, message, description };
      yield put(getBankDetailsFailure(errorPreview));
    }
  }
}

// get Serice Customer Profile

function* customerDetails(action) {
  const { payload } = action;
  try {
    const response = yield $authHost.get(`/users/customer/${payload}`);
    if (response.status === 200) {
      console.log(response);
      yield put(getUserProfileSuccess(response?.data?.data?.user_details));
    }
  } catch (error) {
    if (error.response.status === 500) {
      const errorPreview = {
        code: 500,
        message: 'Internal Server Error',
        description: 'Internal Server Error',
      };
      yield put(getUserProfileFailure(errorPreview));
    }
    if (error.response && error.response.data) {
      const { code, message, description } = error.response.data;
      const errorPreview = { code, message, description };
      yield put(getUserProfileFailure(errorPreview));
    }
  }
}


export default function* profileSaga() {
  yield takeLatest(updateProfile.type, profileUpdate);
  yield takeLatest(updateBusinessProofOfAddress.type, businessProofOfAddress);
  yield takeLatest(updateProofOfCertification.type, businessProofOfCertification);
  yield takeLatest(updateProofOfLicense.type, businessProofOfLicense);
  yield takeLatest(getBankDetails.type, businessBankDetails);
  yield takeLatest(getUserProfile.type, customerDetails);
}
